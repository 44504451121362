import React from "react";
import { get } from "lodash";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import Layout from "../layouts/layout.js";
import SEO from "../components/Seo";
import "twin.macro";
import Header from "../components/Header";
import SplitSection from "../components/SplitSection";
import FlexSection from "../components/FlexSection";
import Testimonial from "../components/Testimonial";
import CtaBlock from "../components/CtaBlock";
import { SolidButton, H1, H3 } from "../utils/helpers";
import { htmlSerializer } from "../utils/htmlSerializer";
import { linkResolver } from "@prismicio/gatsby-source-prismic-graphql";

const Drivers = ({ data }) => {
  const page = get(data, "prismic.allDrivers_pages.edges[0].node");
  if (!page) return null;
  // console.log(page);
  return (
    <Layout>
      <SEO title={RichText.asText(page.title)} />
      <Header bgImage="https://images.unsplash.com/photo-1524345591067-da3f166c0e42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1867&q=80">
        <H1>{RichText.asText(page.title)}</H1>
      </Header>
      {page.body[0].fields.map(({ header, image, text, imageSharp }, idx) => (
        <SplitSection
          fluid={imageSharp.childImageSharp.fluid}
          pos={idx}
          key={`split-${idx}`}
        >
          <H3>{RichText.asText(header)}</H3>
          <RichText
            render={text}
            htmlSerializer={htmlSerializer}
            linkResolver={linkResolver}
          />
          <SolidButton
            to={
              page.cta_link !== null
                ? page.cta_link.url
                : "https://web.fountain.com/c/jeevz/jeevz/US"
            }
          >
            Apply Now
          </SolidButton>
        </SplitSection>
      ))}
      <section tw="bg-gray-50">
        <FlexSection>
          <div tw="px-4 md:pr-12">
            <H3>{RichText.asText(page.standards_title)}</H3>
            <RichText
              render={page.standards_text}
              htmlSerializer={htmlSerializer}
              linkResolver={linkResolver}
            />
            <SolidButton
              to={
                page.cta_link !== null
                  ? page.cta_link.url
                  : "https://web.fountain.com/c/jeevz/jeevz/US"
              }
            >
              Apply Now
            </SolidButton>
          </div>
          <Testimonial
            quote={RichText.asText(page.testimonial.quote)}
            author={page.testimonial.author}
            authorInfo={page.testimonial.bio}
            authorBio={page.testimonial.photo.url}
            right
          />
        </FlexSection>
      </section>
      <CtaBlock cta="Contact Us" ctalink="mailto:support@jeevz.com">
        Questions? Contact us today.
      </CtaBlock>
    </Layout>
  );
};

export default Drivers;

export const query = graphql`
  query DriversQuery {
    prismic {
      allDrivers_pages {
        edges {
          node {
            body {
              ... on PRISMIC_Drivers_pageBodySplit_block {
                type
                label
                fields {
                  header
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 85) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  text
                }
              }
            }
            cta_link {
              _linkType
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            header_image
            header_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 85) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            standards_text
            standards_title
            testimonial {
              ... on PRISMIC_Testimonial {
                quote
                author
                bio
                photo
              }
            }
            title
          }
        }
      }
    }
  }
`;
