import React from "react";
import tw from "twin.macro";

const FlexWrapper = tw.div`
  relative mx-auto py-8 md:py-16 md:flex items-center justify-center max-w-6xl
`;

const FlexSection = ({ children }) => <FlexWrapper>{children}</FlexWrapper>;

export default FlexSection;
